<template>
    <div class="init">
        <el-form :model="fromValue" label-position="top" label-width="auto" ref='form_' class="fromClass"
            :rules='rules'>
            <el-form-item :label="$t('currencySet.name1')" prop="userId">
                <el-select v-model="fromValue.userId" :placeholder="$t('currencySetNft.name1')" disabled>
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('currencySet.name2')" prop="appId">
                <el-select v-model="fromValue.appId" :placeholder="$t('currencySetNft.name2')" disabled>
                    <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('currencySetNft.name3')" prop="allCoin">
                <el-input v-model="fromValue.allCoin"  :placeholder="$t('inputTit.xuanze')" disabled />
            </el-form-item>
            <el-form-item :label="$t('otherWallatNft.text3')" prop="allCoin">
                <el-radio-group v-model="fromValue.isWallet" size="large" disabled>
                    <el-radio-button :label="1">{{$t('otherWallatNft.text4')}}</el-radio-button>
                    <el-radio-button :label="2">{{$t('otherWallatNft.text5')}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('otherWallatNft.text6')" prop="walletAddress"  v-if='fromValue.isWallet === 2'>
                <el-input v-model="fromValue.walletAddress" disabled  :placeholder="$t('inputTit.xuanze')" />
            </el-form-item>
            <el-form-item :label="$t('otherWallatNft.name1')" prop="walletName">
                <el-input v-model="fromValue.walletName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('otherWallatNft.name2')" prop="priority">
                <el-input v-model="fromValue.priority" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <!-- <el-row :gutter="20" justify="end">
                <el-col :span="5" >
                    <el-button @click="emit('successFun')">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.compla')}}</el-button>
                </el-col>
            </el-row> -->
        </el-form>
    </div>
</template>
<script setup>
    import { ref,reactive,defineEmits,getCurrentInstance,defineExpose } from 'vue'
    import { userStore } from '@/store/user'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import rules1 from './rules'
    import apiList from '@/const/apiList'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        userId: '',userType: 2,tenantId:'',
        appId:'',
        allCoin:'',chain:'',coin:'',
        isWallet: 1,
        walletAddress:'',walletName:'',priority:1,
        googleCode:'',
        protocolType: 2,
        walletType:2,walletInfoId:'',
    })
    //初始化是平台还是商家
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    //初始化商家和app数组
    const tenantArray = ref([])
    const appArray = ref([])
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;})
    // fromValue
    const emit = defineEmits(['successFun'])
    const form_ = ref(null)
    const rules = ref(null);rules.value = rules1.mainRules
    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                delete obj_.allCoin
                delete obj_.googleCode
                Api_.upAssetswalletinfoStatus(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0 && res.data){
                        emit('successFun')
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const init_ = (e)=>{
        if(e){
            for(let k in fromValue){
                fromValue[k] = e[k]
            }
        }
        fromValue.isWallet = 2
        fromValue.allCoin = fromValue.chain + '/' + fromValue.coin
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
</style>